body {
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
}

.pushy-open-right {
    transition: 5s;

    .menu-btn {
        z-index: 99999;
        position: absolute;
        right: 40px;
        cursor: pointer;
        display: block;

        &:focus {
            outline: 0 !important;
        }

        span {
            display: none;
        }

        .close {
            height: 37px;
            width: 37px;

            &:focus {
                outline: 0 !important;
            }
        }

        .close:after {
            position: relative;
            content: "\d7";
            font-size: 100px;
            color: white;
            font-weight: normal;
            top: -40px;
            left: -2px
        }
    }

    .menu-close {
        display: none;
    }
}

.menu-btn {
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0;
    display: none;

    @media screen and (max-width: 991px) {
        opacity: 1;
    }

    span {
        width: 40px;
        height: 5px;
        background: #227eb7 !important;
        margin-bottom: 5px;
        display: block;
        background: none;
        border: none;
    }
}

.cookies {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    transition: 0.3s;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    opacity: 1;

    &.close {
        opacity: 0;
        z-index: -1;
    }

    .container {
        transition: 0.3s;
        display: flex;
        align-items: center;
        background: white;
        box-shadow: 5px 2px 5px 2px rgba(0, 0, 0, 0.2);
        max-width: 1920px;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        justify-content: center;

        .info {
            width: 15%;
            text-align: center;
            color: #077b93;
            font-weight: 500;
            font-size: 20px;
            padding: 20px;
            border-right: 1px solid #077b93;

            @media screen and (max-width: 767px) {
                width: 100%;
                padding-bottom: 30px;
                border-right: 0px;
            }
        }

        .text {
            width: 70%;
            padding: 20px;
            color: #013a5d;
            font-size: 13px;
            font-weight: 300;
        }

        .accept {
            background: #077b93;
            height: 40px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: Center;
            padding-left: 20px;
            padding-right: 20px;
            max-width: 150px;
            width: 100%;
            cursor: pointer;
        }
    }
}

.header-menu {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;

    ul {
        display: flex;
        list-style: none;

        @media screen and (max-width: 991px) {
            display: none;
        }

        li {
            padding-right: 35px;

            &:last-child {
                padding-right: 0px;
            }

            a {
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 16px;
                color: #6a6b6c;
                padding-bottom: 30px;

                &:hover {
                    color: #227eb7;
                    border-bottom: 3px solid #227eb7;
                }

                &.active {
                    color: #227eb7;
                    border-bottom: 3px solid #227eb7;
                }
            }
        }
    }
}

main {
    .mainSlider {
        height: 755px;

        .slider-section {
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            height: 755px;
            display: flex;
            justify-content: center;

            .container {
                padding-top: 150px;

                .title {
                    font-size: 55px;
                    font-weight: 400;
                    color: white;
                    text-align: center;
                    width: fit-content;
                    margin: 0 auto;
                    background: rgba(38, 140, 204, 0.7);
                    padding-left: 50px;
                    padding-right: 50px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                .text {
                    color: #063878;
                    font-size: 28px;
                    padding-left: 70px;
                    padding-right: 70px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    background: rgba(255, 255, 255, 0.7);
                    margin: 0 auto;
                    text-align: center;
                    width: fit-content;
                }
            }
        }

        &.subpage {
            height: 335px;

            .slider-section {
                height: 335px;

                .container {
                    padding-top: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
        }
    }

    .box-section {
        display: flex;
        flex-wrap: wrap;
        background: white;
        padding: 15px;
        margin-top: -275px;

        .box {
            max-width: 360px;
            width: 100%;
            text-decoration: none;
            margin: 0 auto;
            transition: 0.2s;
            position: relative;
            padding-bottom: 15px;
            transition: 1s;

            @media screen and (max-width: 991px) {
                max-width: 330px;
            }

            img {
                max-width: 360px;
                width: 100%;
                height: 200px;
                display: block;
            }

            .text {
                color: #227eb7;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.5;
                height: 65px;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                max-width: 360px;
                width: 100%;
                border: 1px solid #d7ecf8;
                box-sizing: border-box;
                transition: 0.5s;


                &:before {
                    width: 0;
                    height: 0;
                    border-left: 180px solid transparent;
                    border-right: 180px solid transparent;
                    border-bottom: 50px solid white;

                    position: absolute;
                    content: '';
                    top: -50px;
                    z-index: 1;
                    opacity: 1;
                    transition: 0.5s;
                }

                span {
                    width: 175px;
                    text-align: center;
                    top: -20px;
                    position: relative;
                    z-index: 2;
                    text-transform: uppercase;
                }
            }

            &:hover .text {
                background: rgba(38, 140, 204, 1.0);

                span {
                    color: white;
                }

                &:before {
                    width: 0;
                    height: 0;
                    border-bottom: 50px solid rgba(38, 140, 204, 1.0);
                    position: absolute;
                    content: '';
                    top: -50px;
                    z-index: 1;

                }
            }
        }

        .box-more {
            max-width: 360px;
            width: 100%;
            height: 265px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            display: flex;
            border: 1px solid #d7ecf8;
            box-sizing: border-box;

            @media screen and (max-width: 991px) {
                max-width: 330px;
                margin: 0 auto;
                padding-bottom: 15px;
            }

            span {
                width: 175px;
                color: #227eb7;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.5;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }

    .wrapper-courses {
        background: #e7f5fb;
        min-height: 580px;
        height: 100%;
        width: 100%;
        margin-top: 90px;
        display: flex;
        align-items: center;
        position: relative;

        @media screen and (max-width: 1199px) {
            min-height: 300px;
        }

        .left {
            display: flex;
            justify-content: flex-start;
            width: 50%;
            position: absolute;
            left: 0;
            top: -50px;

            @media screen and (max-width: 1199px) {
                display: none;
            }

            img {
                max-width: 1089px;
                width: 100%;
                height: auto;
                display: block;
                position: relative;
                z-index: 1;
            }

            &:before {
                width: 0;
                height: 0;
                border-top: 290px solid transparent;
                border-bottom: 290px solid transparent;
                border-left: 100px solid #e7f5fb;
                position: absolute;
                content: '';
                top: 50px;
                right: -95px;
                z-index: 1;
            }
        }

        .right {
            position: absolute;
            width: 50%;
            background: #227eb7;
            padding-top: 65px;
            padding-bottom: 65px;
            padding-left: 150px;
            box-sizing: border-box;
            right: 0;

            @media screen and (max-width: 1199px) {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                left: 0;
                padding-left: 15px;
                padding-right: 15px;
                box-sizing: border-box;
                position: static;
            }

            .title {
                font-weight: 500;
                font-size: 30px;
                color: white;
                text-transform: uppercase;
            }

            .text {
                font-size: 14px;
                color: white;
                font-weight: 400;
                padding-top: 20px;
                padding-bottom: 20px;
                line-height: 1.8;
                max-width: 405px;
                width: 100%;

                @media screen and (max-width: 1199px) {
                    width: 100%;
                    max-width: 900px;
                    text-align: center;
                }
            }

            .btn-more {
                background: white;
                padding-left: 25px;
                padding-right: 25px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #227eb7;
                font-weight: 400;
                text-transform: uppercase;
                width: fit-content;
                text-decoration: none;
            }
        }
    }

    .our-aces {
        display: flex;
        flex-wrap: wrap;
        margin-top: 90px;
        margin-bottom: 90px;

        .boxes {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media screen and (max-width: 1199px) {
                max-width: 585px;
                margin: 0 auto;
            }

            @media screen and (min-width:1199px) {
                width: 65%;
            }

            .box {
                background: #227eb7;
                max-width: 280px;
                width: 100%;
                height: 280px;
                padding: 15px;
                color: white;
                font-size: 14px;
                font-weight: 400;
                box-sizing: border-box;

                &:nth-of-type(2) {
                    background: #288fcf;
                }

                &:nth-of-type(3) {
                    background: #288fcf;
                }

                img {
                    margin-top: 30px;
                }

                .text {
                    margin-top: 25px;
                    padding-top: 25px;
                    padding-right: 10px;
                    color: white;
                    border-top: 1px solid #64a1c7;
                }

                @media screen and (max-width:767px) {
                    margin-bottom: 25px;
                }
            }
        }

        .content {
            padding-top: 40px;
            padding-bottom: 40px;
            max-width: 500px;
            width: 100%;
            margin: 0 auto;

            @media screen and (min-width: 1199px) {
                width: 35%;
            }

            .title {
                font-size: 30px;
                font-weight: 500;
                color: #227eb7;
                text-transform: uppercase;
                padding-bottom: 20px;
            }

            .text {
                color: #013a5d;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
            }
        }
    }

    .news-section {
        background: #f1f1f1;
        padding-top: 50px;
        padding-bottom: 50px;

        .news-container {
            .title-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .title {
                    color: #227eb7;
                    font-size: 30px;
                    font-weight: 500;
                    text-transform: uppercase;
                }

                .btn-more {
                    font-weight: 500;
                    font-size: 14px;
                    color: #227eb7;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }

            .news-boxes {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding-top: 50px;

                .box {
                    max-width: 260px;
                    width: 100%;
                    height: 290px;
                    border-top: 3px solid #288fcf;
                    box-sizing: border-box;
                    background: white;
                    position: relative;
                    padding: 20px;
                    margin: 0 auto 20px auto;
                    text-decoration: none;

                    .date {
                        font-size: 12px;
                        color: #288fcf;
                        font-weight: 500;
                    }

                    .category {
                        font-size: 12px;
                        color: #288fcf;
                        font-weight: 500;
                    }

                    .title {
                        color: #288fcf;
                        font-weight: 500;
                        font-size: 16px;
                        text-transform: uppercase;
                        position: absolute;
                        bottom: 20px;
                        width: 175px;
                    }

                    &:first-child {
                        background: #288fcf;

                        .date,
                        .category,
                        .title {
                            color: white;
                        }
                    }

                    &:hover {
                        background: #288fcf;

                        .date,
                        .category,
                        .title {
                            color: white;
                        }
                    }
                }
            }
        }

        .form-section {
            display: flex;
            flex-wrap: wrap;
            padding-top: 50px;
            padding-bottom: 50px;

            .left {
                width: 100%;
                display: flex;
                position: relative;

                @media screen and (min-width: 992px) {
                    width: 50%;
                }

                img {
                    display: block;
                    max-width: 837px;
                    width: 100%;
                    height: auto;

                    @media screen and (max-width: 991px) {
                        display: none;
                    }
                }

                .entry-form {
                    width: 215px;
                    height: 215px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #288fcf;
                    position: absolute;
                    border-radius: 120px;
                    flex-direction: column;
                    position: absolute;
                    right: 10px;
                    top: 100px;

                    span {
                        color: white;
                        font-weight: 500;
                        font-size: 23px;
                        width: 175px;
                        text-align: center;
                    }

                    @media screen and (max-width: 991px) {
                        display: none;
                    }

                    a {
                        color: white;
                        text-decoration: none;
                        margin-top: 10px;
                    }
                }
            }

            .right {
                width: 100%;

                @media screen and (min-width: 992px) {
                    width: 50%;
                    display: flex;
                    justify-content: flex-end;
                }

                form {
                    border: 10px solid #c1dae9;
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-top: 50px;
                    padding-bottom: 50px;

                    @media screen and (min-width: 992px) {
                        width: 90%;
                    }

                    .title {
                        color: #288fcf;
                        font-size: 36px;
                        font-weight: 400;
                        width: fit-content;
                        margin: 0 auto;
                        padding-bottom: 40px;
                    }

                    input {
                        border: 1px solid #c1dae9;
                        height: 45px;
                        width: 100%;
                        color: #013a5d;
                        font-size: 14px;
                        background: transparent;
                        margin-bottom: 20px;
                        padding-left: 20px;
                        box-sizing: border-box;

                        &::placeholder {
                            color: #013a5d;
                        }
                    }

                    textarea {
                        border: 1px solid #c1dae9;
                        height: 100px;
                        width: 100%;
                        color: #013a5d;
                        font-size: 14px;
                        background: transparent;
                        margin-bottom: 20px;
                        padding-left: 20px;
                        box-sizing: border-box;
                        padding-top: 20px;
                    }

                    button {
                        color: white;
                        font-size: 14px;
                        background: #288fcf;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                        padding-left: 25px;
                        padding-right: 25px;
                        text-transform: uppercase;
                        font-weight: 500;
                        margin: 0 auto;
                        border: none;
                    }
                }
            }
        }
    }
}

footer {
    .map-section {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .map {
            width: 50%;

            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }

        .right {
            width: 50%;
            padding-left: 50px;
            box-sizing: border-box;

            @media screen and (max-width: 991px) {
                width: 100%;
                display: flex;
                padding-left: 0px;
                justify-content: center;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .contact {
                display: flex;
                flex-wrap: wrap;
                max-width: 530px;
                width: 100%;

                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    margin-bottom: 40px;

                    &:nth-of-type(3),
                    &:nth-of-type(4) {
                        margin-bottom: 0px;
                    }

                    .content {
                        width: 180px;
                        margin-left: 20px;

                        div {
                            font-weight: 500;
                            font-size: 16px;
                            color: #013a5d;
                            width: 150px;
                        }

                        div:first-child {
                            color: #a19d9d;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    .created-by {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .header-menu {
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
            padding-bottom: 15px;
            align-items: center;

            ul {
                display: flex;
                list-style: none;

                @media screen and (max-width: 991px) {
                    display: none;
                }

                li {
                    padding-right: 35px;

                    &:last-child {
                        padding-right: 0px;
                    }

                    a {
                        text-decoration: none;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 16px;
                        color: #6a6b6c;
                        padding-bottom: 30px;

                        &:hover {
                            color: #227eb7;
                            border-bottom: 3px solid #227eb7;
                        }

                        &.active {
                            color: #227eb7;
                            border-bottom: 3px solid #227eb7;
                        }
                    }
                }
            }
        }

        .created {
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            border-top: 1px solid #e9edef;
            margin-top: 30px;

            .container {
                display: flex;
                justify-content: space-between;

                a {
                    font-size: 12px;
                    color: #288fcf;
                    font-weight: 500;
                    text-decoration: none;
                }
            }
        }
    }
}



// subpage

main {
    .subpage-container {
        padding-top: 60px;
        padding-bottom: 60px;

        .title-subpage {
            color: #227eb7;
            font-size: 30px;
            text-transform: uppercase;
        }

        .text {
            color: #013a5d;
            font-size: 14px;
            width: 100%;
            padding-top: 30px;
            line-height: 1.5;
        }

        .gallery {
            display: flex;
            flex-wrap: wrap;
            padding-top: 50px;

            .box {
                max-width: 360px;
                height: 305px;
                width: 100%;
                margin: 0 auto;
                padding: 5px;

                @media only screen and (max-width: 1199px) and (min-width:992px) {
                    max-width: 295px;
                    height: auto;
                }

                img {
                    max-width: 360px;
                    width: 100%;
                    height: 305px;

                    @media only screen and (max-width: 1199px) and (min-width:992px) {
                        max-width: 2950px;
                        height: auto;
                    }
                }
            }
        }

        .offer-boxes {
            display: flex;
            flex-wrap: wrap;
            padding-top: 50px;

            .box {
                background: #227eb7;
                max-width: 360px;
                width: 100%;
                height: 135px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                margin-bottom: 10px;
                text-decoration: none;

                span {
                    color: white;
                    font-size: 14px;
                    width: 190px;
                    text-align: center;
                }
            }
        }
    }

    .forms-section {
        display: flex;
        flex-wrap: wrap;
        padding-top: 50px;

        .box {
            margin: auto;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 280px;
            text-decoration: none;
            flex-direction: column;

            span {
                width: 270px;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                padding-top: 20px;
            }
        }
    }

    .news-boxes {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 50px;
        margin: auto;

        .box {
            max-width: 290px;
            width: 100%;
            height: 290px;
            border-top: 3px solid #288fcf;
            border-left: 1px solid #288fcf;
            border-right: 1px solid #288fcf;
            border-bottom: 1px solid #288fcf;
            box-sizing: border-box;
            background: white;
            position: relative;
            padding: 20px;
            margin: 20px;
            text-decoration: none;

            .date {
                font-size: 12px;
                color: #288fcf;
                font-weight: 500;
            }

            .category {
                font-size: 12px;
                color: #288fcf;
                font-weight: 500;
            }

            .title {
                color: #288fcf;
                font-weight: 500;
                font-size: 16px;
                text-transform: uppercase;
                position: absolute;
                bottom: 20px;
                width: 175px;
            }

            &.newest {
                background: #288fcf;

                .date,
                .category,
                .title {
                    color: white;
                }
            }

            &:hover {
                background: #288fcf;

                .date,
                .category,
                .title {
                    color: white;
                }
            }
        }
    }

    .form-section {
        display: flex;
        flex-wrap: wrap;
        padding-top: 50px;
        padding-bottom: 50px;

        .left {
            width: 100%;
            display: flex;
            position: relative;

            @media screen and (min-width: 992px) {
                width: 50%;
            }

            .contact-box {
                display: flex;
                max-width: 470px;
                width: 100%;
                flex-wrap: wrap;
                position: relative;
                height: 100px;

                @media screen and (max-width: 991px) {
                    max-width: 900px;
                    padding-bottom: 50px;
                }

                .text- {
                    font-size: 14px;
                    color: #013a5d;
                    font-weight: 400;
                    width: 50%;
                    padding-top: 5px;


                    &.name {
                        font-weight: 500;
                        width: 50%;
                    }

                    &.adres {
                        width: 140px;
                    }
                }
            }

            img {
                display: block;
                max-width: 837px;
                width: 100%;
                height: auto;
                position: absolute;
                left: 0;
                bottom: 0;

                @media screen and (max-width: 991px) {
                    display: none;
                }
            }

            .entry-form {
                width: 215px;
                height: 215px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #288fcf;
                position: absolute;
                border-radius: 120px;
                flex-direction: column;
                position: absolute;
                right: 10px;
                top: 150px;

                span {
                    color: white;
                    font-weight: 500;
                    font-size: 23px;
                    width: 175px;
                    text-align: center;
                }

                a {
                    color: white;
                    text-decoration: none;
                    margin-top: 10px;
                }

                @media screen and (max-width:991px) {
                    display: none;
                }
            }
        }

        .right {
            width: 100%;

            @media screen and (min-width: 992px) {
                width: 50%;
                display: flex;
                justify-content: flex-end;
            }

            form {
                border: 10px solid #c1dae9;
                width: 100%;
                box-sizing: border-box;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 50px;
                padding-bottom: 50px;

                @media screen and (min-width: 992px) {
                    width: 90%;
                }

                .title {
                    color: #288fcf;
                    font-size: 36px;
                    font-weight: 400;
                    width: fit-content;
                    margin: 0 auto;
                    padding-bottom: 40px;
                }

                input {
                    border: 1px solid #c1dae9;
                    height: 45px;
                    width: 100%;
                    color: #013a5d;
                    font-size: 14px;
                    background: transparent;
                    margin-bottom: 20px;
                    padding-left: 20px;
                    box-sizing: border-box;

                    &::placeholder {
                        color: #013a5d;
                    }
                }

                textarea {
                    border: 1px solid #c1dae9;
                    height: 100px;
                    width: 100%;
                    color: #013a5d;
                    font-size: 14px;
                    background: transparent;
                    margin-bottom: 20px;
                    padding-left: 20px;
                    box-sizing: border-box;
                    padding-top: 20px;
                }

                button {
                    color: white;
                    font-size: 14px;
                    background: #288fcf;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    padding-left: 25px;
                    padding-right: 25px;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin: 0 auto;
                    border: none;
                }
            }
        }
    }
}