// window sizes 
$extra-large: "(min-width:1200px)";
$large: "(min-width:992px) and (max-width:1199px)";
$medium: "(min-width: 768px) and (max-width:991px)";
$small: "(min-width:576px) and (max-width:767px)";
$extra-small: "(max-width:576px)";

body {
	font-family: 'Poppins', sans-serif;
	margin: 0 auto;
	overflow-x: hidden;
}

h1 {
	margin: 0px !important;
}

// RWD
.container {

	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
	box-sizing: border-box;
	position: relative;
	width: 100%;

	@media #{$extra-large} {
		max-width: 1140px;
	}

	@media #{$large} {
		max-width: 960px;
	}

	@media #{$medium} {
		max-width: 720px;
	}

	@media #{$small} {
		max-width: 540px;
	}
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}