body {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto; }

.pushy-open-right {
  transition: 5s; }
  .pushy-open-right .menu-btn {
    z-index: 99999;
    position: absolute;
    right: 40px;
    cursor: pointer;
    display: block; }
    .pushy-open-right .menu-btn:focus {
      outline: 0 !important; }
    .pushy-open-right .menu-btn span {
      display: none; }
    .pushy-open-right .menu-btn .close {
      height: 37px;
      width: 37px; }
      .pushy-open-right .menu-btn .close:focus {
        outline: 0 !important; }
    .pushy-open-right .menu-btn .close:after {
      position: relative;
      content: "\d7";
      font-size: 100px;
      color: white;
      font-weight: normal;
      top: -40px;
      left: -2px; }
  .pushy-open-right .menu-close {
    display: none; }

.menu-btn {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  display: none; }
  @media screen and (max-width: 991px) {
    .menu-btn {
      opacity: 1; } }
  .menu-btn span {
    width: 40px;
    height: 5px;
    background: #227eb7 !important;
    margin-bottom: 5px;
    display: block;
    background: none;
    border: none; }

.cookies {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  transition: 0.3s;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  opacity: 1; }
  .cookies.close {
    opacity: 0;
    z-index: -1; }
  .cookies .container {
    transition: 0.3s;
    display: flex;
    align-items: center;
    background: white;
    box-shadow: 5px 2px 5px 2px rgba(0, 0, 0, 0.2);
    max-width: 1920px;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center; }
    .cookies .container .info {
      width: 15%;
      text-align: center;
      color: #077b93;
      font-weight: 500;
      font-size: 20px;
      padding: 20px;
      border-right: 1px solid #077b93; }
      @media screen and (max-width: 767px) {
        .cookies .container .info {
          width: 100%;
          padding-bottom: 30px;
          border-right: 0px; } }
    .cookies .container .text {
      width: 70%;
      padding: 20px;
      color: #013a5d;
      font-size: 13px;
      font-weight: 300; }
    .cookies .container .accept {
      background: #077b93;
      height: 40px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: Center;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 150px;
      width: 100%;
      cursor: pointer; }

.header-menu {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center; }
  .header-menu ul {
    display: flex;
    list-style: none; }
    @media screen and (max-width: 991px) {
      .header-menu ul {
        display: none; } }
    .header-menu ul li {
      padding-right: 35px; }
      .header-menu ul li:last-child {
        padding-right: 0px; }
      .header-menu ul li a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        color: #6a6b6c;
        padding-bottom: 30px; }
        .header-menu ul li a:hover {
          color: #227eb7;
          border-bottom: 3px solid #227eb7; }
        .header-menu ul li a.active {
          color: #227eb7;
          border-bottom: 3px solid #227eb7; }

main .mainSlider {
  height: 755px; }
  main .mainSlider .slider-section {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 755px;
    display: flex;
    justify-content: center; }
    main .mainSlider .slider-section .container {
      padding-top: 150px; }
      main .mainSlider .slider-section .container .title {
        font-size: 55px;
        font-weight: 400;
        color: white;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
        background: rgba(38, 140, 204, 0.7);
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 15px;
        padding-bottom: 15px; }
      main .mainSlider .slider-section .container .text {
        color: #063878;
        font-size: 28px;
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 7px;
        padding-bottom: 7px;
        background: rgba(255, 255, 255, 0.7);
        margin: 0 auto;
        text-align: center;
        width: fit-content; }
  main .mainSlider.subpage {
    height: 335px; }
    main .mainSlider.subpage .slider-section {
      height: 335px; }
      main .mainSlider.subpage .slider-section .container {
        padding-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }

main .box-section {
  display: flex;
  flex-wrap: wrap;
  background: white;
  padding: 15px;
  margin-top: -275px; }
  main .box-section .box {
    max-width: 360px;
    width: 100%;
    text-decoration: none;
    margin: 0 auto;
    transition: 0.2s;
    position: relative;
    padding-bottom: 15px;
    transition: 1s; }
    @media screen and (max-width: 991px) {
      main .box-section .box {
        max-width: 330px; } }
    main .box-section .box img {
      max-width: 360px;
      width: 100%;
      height: 200px;
      display: block; }
    main .box-section .box .text {
      color: #227eb7;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      height: 65px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      max-width: 360px;
      width: 100%;
      border: 1px solid #d7ecf8;
      box-sizing: border-box;
      transition: 0.5s; }
      main .box-section .box .text:before {
        width: 0;
        height: 0;
        border-left: 180px solid transparent;
        border-right: 180px solid transparent;
        border-bottom: 50px solid white;
        position: absolute;
        content: '';
        top: -50px;
        z-index: 1;
        opacity: 1;
        transition: 0.5s; }
      main .box-section .box .text span {
        width: 175px;
        text-align: center;
        top: -20px;
        position: relative;
        z-index: 2;
        text-transform: uppercase; }
    main .box-section .box:hover .text {
      background: #268ccc; }
      main .box-section .box:hover .text span {
        color: white; }
      main .box-section .box:hover .text:before {
        width: 0;
        height: 0;
        border-bottom: 50px solid #268ccc;
        position: absolute;
        content: '';
        top: -50px;
        z-index: 1; }
  main .box-section .box-more {
    max-width: 360px;
    width: 100%;
    height: 265px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    display: flex;
    border: 1px solid #d7ecf8;
    box-sizing: border-box; }
    @media screen and (max-width: 991px) {
      main .box-section .box-more {
        max-width: 330px;
        margin: 0 auto;
        padding-bottom: 15px; } }
    main .box-section .box-more span {
      width: 175px;
      color: #227eb7;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      text-transform: uppercase;
      text-align: center; }

main .wrapper-courses {
  background: #e7f5fb;
  min-height: 580px;
  height: 100%;
  width: 100%;
  margin-top: 90px;
  display: flex;
  align-items: center;
  position: relative; }
  @media screen and (max-width: 1199px) {
    main .wrapper-courses {
      min-height: 300px; } }
  main .wrapper-courses .left {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    position: absolute;
    left: 0;
    top: -50px; }
    @media screen and (max-width: 1199px) {
      main .wrapper-courses .left {
        display: none; } }
    main .wrapper-courses .left img {
      max-width: 1089px;
      width: 100%;
      height: auto;
      display: block;
      position: relative;
      z-index: 1; }
    main .wrapper-courses .left:before {
      width: 0;
      height: 0;
      border-top: 290px solid transparent;
      border-bottom: 290px solid transparent;
      border-left: 100px solid #e7f5fb;
      position: absolute;
      content: '';
      top: 50px;
      right: -95px;
      z-index: 1; }
  main .wrapper-courses .right {
    position: absolute;
    width: 50%;
    background: #227eb7;
    padding-top: 65px;
    padding-bottom: 65px;
    padding-left: 150px;
    box-sizing: border-box;
    right: 0; }
    @media screen and (max-width: 1199px) {
      main .wrapper-courses .right {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        position: static; } }
    main .wrapper-courses .right .title {
      font-weight: 500;
      font-size: 30px;
      color: white;
      text-transform: uppercase; }
    main .wrapper-courses .right .text {
      font-size: 14px;
      color: white;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 1.8;
      max-width: 405px;
      width: 100%; }
      @media screen and (max-width: 1199px) {
        main .wrapper-courses .right .text {
          width: 100%;
          max-width: 900px;
          text-align: center; } }
    main .wrapper-courses .right .btn-more {
      background: white;
      padding-left: 25px;
      padding-right: 25px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #227eb7;
      font-weight: 400;
      text-transform: uppercase;
      width: fit-content;
      text-decoration: none; }

main .our-aces {
  display: flex;
  flex-wrap: wrap;
  margin-top: 90px;
  margin-bottom: 90px; }
  main .our-aces .boxes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media screen and (max-width: 1199px) {
      main .our-aces .boxes {
        max-width: 585px;
        margin: 0 auto; } }
    @media screen and (min-width: 1199px) {
      main .our-aces .boxes {
        width: 65%; } }
    main .our-aces .boxes .box {
      background: #227eb7;
      max-width: 280px;
      width: 100%;
      height: 280px;
      padding: 15px;
      color: white;
      font-size: 14px;
      font-weight: 400;
      box-sizing: border-box; }
      main .our-aces .boxes .box:nth-of-type(2) {
        background: #288fcf; }
      main .our-aces .boxes .box:nth-of-type(3) {
        background: #288fcf; }
      main .our-aces .boxes .box img {
        margin-top: 30px; }
      main .our-aces .boxes .box .text {
        margin-top: 25px;
        padding-top: 25px;
        padding-right: 10px;
        color: white;
        border-top: 1px solid #64a1c7; }
      @media screen and (max-width: 767px) {
        main .our-aces .boxes .box {
          margin-bottom: 25px; } }
  main .our-aces .content {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 500px;
    width: 100%;
    margin: 0 auto; }
    @media screen and (min-width: 1199px) {
      main .our-aces .content {
        width: 35%; } }
    main .our-aces .content .title {
      font-size: 30px;
      font-weight: 500;
      color: #227eb7;
      text-transform: uppercase;
      padding-bottom: 20px; }
    main .our-aces .content .text {
      color: #013a5d;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5; }

main .news-section {
  background: #f1f1f1;
  padding-top: 50px;
  padding-bottom: 50px; }
  main .news-section .news-container .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    main .news-section .news-container .title-section .title {
      color: #227eb7;
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase; }
    main .news-section .news-container .title-section .btn-more {
      font-weight: 500;
      font-size: 14px;
      color: #227eb7;
      text-transform: uppercase;
      text-decoration: none; }
  main .news-section .news-container .news-boxes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px; }
    main .news-section .news-container .news-boxes .box {
      max-width: 260px;
      width: 100%;
      height: 290px;
      border-top: 3px solid #288fcf;
      box-sizing: border-box;
      background: white;
      position: relative;
      padding: 20px;
      margin: 0 auto 20px auto;
      text-decoration: none; }
      main .news-section .news-container .news-boxes .box .date {
        font-size: 12px;
        color: #288fcf;
        font-weight: 500; }
      main .news-section .news-container .news-boxes .box .category {
        font-size: 12px;
        color: #288fcf;
        font-weight: 500; }
      main .news-section .news-container .news-boxes .box .title {
        color: #288fcf;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        position: absolute;
        bottom: 20px;
        width: 175px; }
      main .news-section .news-container .news-boxes .box:first-child {
        background: #288fcf; }
        main .news-section .news-container .news-boxes .box:first-child .date,
        main .news-section .news-container .news-boxes .box:first-child .category,
        main .news-section .news-container .news-boxes .box:first-child .title {
          color: white; }
      main .news-section .news-container .news-boxes .box:hover {
        background: #288fcf; }
        main .news-section .news-container .news-boxes .box:hover .date,
        main .news-section .news-container .news-boxes .box:hover .category,
        main .news-section .news-container .news-boxes .box:hover .title {
          color: white; }
  main .news-section .form-section {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 50px; }
    main .news-section .form-section .left {
      width: 100%;
      display: flex;
      position: relative; }
      @media screen and (min-width: 992px) {
        main .news-section .form-section .left {
          width: 50%; } }
      main .news-section .form-section .left img {
        display: block;
        max-width: 837px;
        width: 100%;
        height: auto; }
        @media screen and (max-width: 991px) {
          main .news-section .form-section .left img {
            display: none; } }
      main .news-section .form-section .left .entry-form {
        width: 215px;
        height: 215px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #288fcf;
        position: absolute;
        border-radius: 120px;
        flex-direction: column;
        position: absolute;
        right: 10px;
        top: 100px; }
        main .news-section .form-section .left .entry-form span {
          color: white;
          font-weight: 500;
          font-size: 23px;
          width: 175px;
          text-align: center; }
        @media screen and (max-width: 991px) {
          main .news-section .form-section .left .entry-form {
            display: none; } }
        main .news-section .form-section .left .entry-form a {
          color: white;
          text-decoration: none;
          margin-top: 10px; }
    main .news-section .form-section .right {
      width: 100%; }
      @media screen and (min-width: 992px) {
        main .news-section .form-section .right {
          width: 50%;
          display: flex;
          justify-content: flex-end; } }
      main .news-section .form-section .right form {
        border: 10px solid #c1dae9;
        width: 100%;
        box-sizing: border-box;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 50px;
        padding-bottom: 50px; }
        @media screen and (min-width: 992px) {
          main .news-section .form-section .right form {
            width: 90%; } }
        main .news-section .form-section .right form .title {
          color: #288fcf;
          font-size: 36px;
          font-weight: 400;
          width: fit-content;
          margin: 0 auto;
          padding-bottom: 40px; }
        main .news-section .form-section .right form input {
          border: 1px solid #c1dae9;
          height: 45px;
          width: 100%;
          color: #013a5d;
          font-size: 14px;
          background: transparent;
          margin-bottom: 20px;
          padding-left: 20px;
          box-sizing: border-box; }
          main .news-section .form-section .right form input::placeholder {
            color: #013a5d; }
        main .news-section .form-section .right form textarea {
          border: 1px solid #c1dae9;
          height: 100px;
          width: 100%;
          color: #013a5d;
          font-size: 14px;
          background: transparent;
          margin-bottom: 20px;
          padding-left: 20px;
          box-sizing: border-box;
          padding-top: 20px; }
        main .news-section .form-section .right form button {
          color: white;
          font-size: 14px;
          background: #288fcf;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          padding-left: 25px;
          padding-right: 25px;
          text-transform: uppercase;
          font-weight: 500;
          margin: 0 auto;
          border: none; }

footer .map-section {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  footer .map-section .map {
    width: 50%; }
    @media screen and (max-width: 991px) {
      footer .map-section .map {
        width: 100%; } }
  footer .map-section .right {
    width: 50%;
    padding-left: 50px;
    box-sizing: border-box; }
    @media screen and (max-width: 991px) {
      footer .map-section .right {
        width: 100%;
        display: flex;
        padding-left: 0px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px; } }
    footer .map-section .right .contact {
      display: flex;
      flex-wrap: wrap;
      max-width: 530px;
      width: 100%; }
      footer .map-section .right .contact .box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin-bottom: 40px; }
        footer .map-section .right .contact .box:nth-of-type(3), footer .map-section .right .contact .box:nth-of-type(4) {
          margin-bottom: 0px; }
        footer .map-section .right .contact .box .content {
          width: 180px;
          margin-left: 20px; }
          footer .map-section .right .contact .box .content div {
            font-weight: 500;
            font-size: 16px;
            color: #013a5d;
            width: 150px; }
          footer .map-section .right .contact .box .content div:first-child {
            color: #a19d9d;
            font-weight: 500;
            text-transform: uppercase; }

footer .created-by {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  footer .created-by .header-menu {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center; }
    footer .created-by .header-menu ul {
      display: flex;
      list-style: none; }
      @media screen and (max-width: 991px) {
        footer .created-by .header-menu ul {
          display: none; } }
      footer .created-by .header-menu ul li {
        padding-right: 35px; }
        footer .created-by .header-menu ul li:last-child {
          padding-right: 0px; }
        footer .created-by .header-menu ul li a {
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
          color: #6a6b6c;
          padding-bottom: 30px; }
          footer .created-by .header-menu ul li a:hover {
            color: #227eb7;
            border-bottom: 3px solid #227eb7; }
          footer .created-by .header-menu ul li a.active {
            color: #227eb7;
            border-bottom: 3px solid #227eb7; }
  footer .created-by .created {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #e9edef;
    margin-top: 30px; }
    footer .created-by .created .container {
      display: flex;
      justify-content: space-between; }
      footer .created-by .created .container a {
        font-size: 12px;
        color: #288fcf;
        font-weight: 500;
        text-decoration: none; }

main .subpage-container {
  padding-top: 60px;
  padding-bottom: 60px; }
  main .subpage-container .title-subpage {
    color: #227eb7;
    font-size: 30px;
    text-transform: uppercase; }
  main .subpage-container .text {
    color: #013a5d;
    font-size: 14px;
    width: 100%;
    padding-top: 30px;
    line-height: 1.5; }
  main .subpage-container .gallery {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px; }
    main .subpage-container .gallery .box {
      max-width: 360px;
      height: 305px;
      width: 100%;
      margin: 0 auto;
      padding: 5px; }
      @media only screen and (max-width: 1199px) and (min-width: 992px) {
        main .subpage-container .gallery .box {
          max-width: 295px;
          height: auto; } }
      main .subpage-container .gallery .box img {
        max-width: 360px;
        width: 100%;
        height: 305px; }
        @media only screen and (max-width: 1199px) and (min-width: 992px) {
          main .subpage-container .gallery .box img {
            max-width: 2950px;
            height: auto; } }
  main .subpage-container .offer-boxes {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px; }
    main .subpage-container .offer-boxes .box {
      background: #227eb7;
      max-width: 360px;
      width: 100%;
      height: 135px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      margin-bottom: 10px;
      text-decoration: none; }
      main .subpage-container .offer-boxes .box span {
        color: white;
        font-size: 14px;
        width: 190px;
        text-align: center; }

main .forms-section {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px; }
  main .forms-section .box {
    margin: auto;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    text-decoration: none;
    flex-direction: column; }
    main .forms-section .box span {
      width: 270px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      padding-top: 20px; }

main .news-boxes {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  margin: auto; }
  main .news-boxes .box {
    max-width: 290px;
    width: 100%;
    height: 290px;
    border-top: 3px solid #288fcf;
    border-left: 1px solid #288fcf;
    border-right: 1px solid #288fcf;
    border-bottom: 1px solid #288fcf;
    box-sizing: border-box;
    background: white;
    position: relative;
    padding: 20px;
    margin: 20px;
    text-decoration: none; }
    main .news-boxes .box .date {
      font-size: 12px;
      color: #288fcf;
      font-weight: 500; }
    main .news-boxes .box .category {
      font-size: 12px;
      color: #288fcf;
      font-weight: 500; }
    main .news-boxes .box .title {
      color: #288fcf;
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      position: absolute;
      bottom: 20px;
      width: 175px; }
    main .news-boxes .box.newest {
      background: #288fcf; }
      main .news-boxes .box.newest .date,
      main .news-boxes .box.newest .category,
      main .news-boxes .box.newest .title {
        color: white; }
    main .news-boxes .box:hover {
      background: #288fcf; }
      main .news-boxes .box:hover .date,
      main .news-boxes .box:hover .category,
      main .news-boxes .box:hover .title {
        color: white; }

main .form-section {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px; }
  main .form-section .left {
    width: 100%;
    display: flex;
    position: relative; }
    @media screen and (min-width: 992px) {
      main .form-section .left {
        width: 50%; } }
    main .form-section .left .contact-box {
      display: flex;
      max-width: 470px;
      width: 100%;
      flex-wrap: wrap;
      position: relative;
      height: 100px; }
      @media screen and (max-width: 991px) {
        main .form-section .left .contact-box {
          max-width: 900px;
          padding-bottom: 50px; } }
      main .form-section .left .contact-box .text- {
        font-size: 14px;
        color: #013a5d;
        font-weight: 400;
        width: 50%;
        padding-top: 5px; }
        main .form-section .left .contact-box .text-.name {
          font-weight: 500;
          width: 50%; }
        main .form-section .left .contact-box .text-.adres {
          width: 140px; }
    main .form-section .left img {
      display: block;
      max-width: 837px;
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0; }
      @media screen and (max-width: 991px) {
        main .form-section .left img {
          display: none; } }
    main .form-section .left .entry-form {
      width: 215px;
      height: 215px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #288fcf;
      position: absolute;
      border-radius: 120px;
      flex-direction: column;
      position: absolute;
      right: 10px;
      top: 150px; }
      main .form-section .left .entry-form span {
        color: white;
        font-weight: 500;
        font-size: 23px;
        width: 175px;
        text-align: center; }
      main .form-section .left .entry-form a {
        color: white;
        text-decoration: none;
        margin-top: 10px; }
      @media screen and (max-width: 991px) {
        main .form-section .left .entry-form {
          display: none; } }
  main .form-section .right {
    width: 100%; }
    @media screen and (min-width: 992px) {
      main .form-section .right {
        width: 50%;
        display: flex;
        justify-content: flex-end; } }
    main .form-section .right form {
      border: 10px solid #c1dae9;
      width: 100%;
      box-sizing: border-box;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 50px;
      padding-bottom: 50px; }
      @media screen and (min-width: 992px) {
        main .form-section .right form {
          width: 90%; } }
      main .form-section .right form .title {
        color: #288fcf;
        font-size: 36px;
        font-weight: 400;
        width: fit-content;
        margin: 0 auto;
        padding-bottom: 40px; }
      main .form-section .right form input {
        border: 1px solid #c1dae9;
        height: 45px;
        width: 100%;
        color: #013a5d;
        font-size: 14px;
        background: transparent;
        margin-bottom: 20px;
        padding-left: 20px;
        box-sizing: border-box; }
        main .form-section .right form input::placeholder {
          color: #013a5d; }
      main .form-section .right form textarea {
        border: 1px solid #c1dae9;
        height: 100px;
        width: 100%;
        color: #013a5d;
        font-size: 14px;
        background: transparent;
        margin-bottom: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        padding-top: 20px; }
      main .form-section .right form button {
        color: white;
        font-size: 14px;
        background: #288fcf;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding-left: 25px;
        padding-right: 25px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0 auto;
        border: none; }

html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-caption a {
  color: #4ae; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

body {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  overflow-x: hidden; }

h1 {
  margin: 0px !important; }

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .container {
      max-width: 540px; } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*! Pushy - v1.1.0 - 2017-1-30
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */
/* Menu Appearance */
.pushy {
  position: fixed;
  max-width: 450px;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: rgba(7, 123, 147, 0.8);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */ }
  .pushy ul {
    list-style: none; }
  .pushy a {
    display: block;
    color: white;
    padding: 15px 30px;
    text-decoration: none; }
  .pushy a:hover {
    color: #FFF; }
  .pushy ul:first-child {
    margin-top: 10px; }
  .pushy.pushy-left {
    left: 0; }
  .pushy.pushy-right {
    right: 0; }

.pushy-content {
  visibility: hidden; }

/* Menu Movement */
.pushy-left {
  -webkit-transform: translate3d(-450px, 0, 0);
  -ms-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0); }

.pushy-open-left #container,
.pushy-open-left .push {
  -webkit-transform: translate3d(450px, 0, 0);
  -ms-transform: translate3d(450px, 0, 0);
  transform: translate3d(450px, 0, 0); }

.pushy-right {
  -webkit-transform: translate3d(450px, 0, 0);
  -ms-transform: translate3d(450px, 0, 0);
  transform: translate3d(450px, 0, 0); }

.pushy-open-right #container,
.pushy-open-right .push {
  -webkit-transform: translate3d(-450px, 0, 0);
  -ms-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0); }

.pushy-open-left .pushy,
.pushy-open-right .pushy {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.pushy-open-left .pushy-content,
.pushy-open-right .pushy-content {
  visibility: visible; }

/* Menu Transitions */
#container,
.pushy,
.push {
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99); }

.pushy-content {
  transition: visibility 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99); }

/* Site Overlay */
.site-overlay {
  display: none; }

.pushy-open-left .site-overlay,
.pushy-open-right .site-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: fade 500ms;
  animation: fade 500ms; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Submenu Appearance */
.pushy-submenu {
  /* Submenu Buttons */
  /* Submenu Icon */ }
  .pushy-submenu ul {
    padding-left: 15px;
    transition: max-height 0.2s ease-in-out, visibility 0.2s ease-in-out; }
    .pushy-submenu ul .pushy-link {
      transition: opacity 0.2s ease-in-out; }
  .pushy-submenu button {
    width: 100%;
    color: #b3b3b1;
    padding: 15px 30px;
    text-align: left;
    background: transparent;
    border: 0; }
    .pushy-submenu button:hover {
      color: #FFF; }
  .pushy-submenu > a,
  .pushy-submenu > button {
    position: relative; }
  .pushy-submenu > a::after,
  .pushy-submenu > button::after {
    content: '';
    display: block;
    height: 11px;
    width: 8px;
    position: absolute;
    top: 50%;
    right: 15px;
    background: url("../img/arrow.svg") no-repeat;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: transform 0.2s; }

/* Submenu Movement */
.pushy-submenu-closed ul {
  max-height: 0;
  overflow: hidden;
  visibility: hidden; }

.pushy-submenu-closed .pushy-link {
  opacity: 0; }

.pushy-submenu-open {
  /* Submenu Icon */ }
  .pushy-submenu-open ul {
    max-height: 1000px;
    visibility: visible; }
  .pushy-submenu-open .pushy-link {
    opacity: 1; }
  .pushy-submenu-open a::after,
  .pushy-submenu-open button::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
